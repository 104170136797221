/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
import { Fade, ListItemText, MenuItem, MenuList, Paper, Popper, styled } from '@mui/material'
import useOnClickOutside from 'hooks/useOnclickoutside'
import { Zalo } from 'icons/zalo'
import { formatPhoneToInternational } from 'utils/formater'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'
import { createMessage } from 'utils/common'
import { MESSAGE_TYPES } from 'constants/common'
import { Telegram } from 'icons/telegram'
import { Avatar } from 'icons/avatar'
import { Department } from 'icons/department'
import { Email } from 'icons/email'
import { PhoneNumber } from 'icons/phonenumber'
import { Copy } from 'icons/copy'

const UserInfoList = styled((props) => {
  const { className, data = {} } = props

  const uiAvatarRef = useRef(null)
  const dispatch = useDispatch()

  return (
    <div className={['user-info-list', className].join(' ')} ref={uiAvatarRef}>
      <MenuList>
        {data?.username && (
          <a>
            <MenuItem sx={{ gap: 1, padding: '14px 20px', borderBottom: '1px solid #EEEEEE' }}>
              <Avatar />
              <ListItemText>{`${data?.name} - ${data?.username}`}</ListItemText>
            </MenuItem>
          </a>
        )}

        {data?.company && (
          <a>
            <MenuItem sx={{ gap: 1, padding: '14px 20px', borderBottom: '1px solid #EEEEEE' }}>
              <Department />
              <ListItemText style={{ whiteSpace: "break-spaces" }}>{data?.company}</ListItemText>
            </MenuItem>
          </a>
        )}

        {data?.email && (
          <CopyToClipboard
            text={data?.email}
            onCopy={() => {
              dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Copy email thành công' }))
            }}
          >
            <a >
              <MenuItem sx={{ gap: 1, padding: '14px 20px', borderBottom: '1px solid #EEEEEE' }}>
                <Email />
                <ListItemText>{data?.email}</ListItemText>
                <Copy />
              </MenuItem>
            </a>
          </CopyToClipboard>
        )}

        {data?.phone && (
          <CopyToClipboard
            text={data?.phone}
            onCopy={() => {
              dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Copy số điện thoại thành công' }))
            }}
          >
            <a>
              <MenuItem sx={{ gap: 1, padding: '14px 20px', borderBottom: '1px solid #EEEEEE' }}>
                <PhoneNumber />
                <ListItemText>{data?.phone}</ListItemText>
                <Copy />
              </MenuItem>
            </a>
          </CopyToClipboard>
        )}

        <CopyToClipboard
          text={data?.telegram}
          onCopy={() => {
            dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Copy số telegram thành công' }))
          }}
        >
          <a
            href={`https://t.me/${formatPhoneToInternational(data?.telegram || data?.phone || '')}`}
            target="_blank"
            rel="noreferrer"
          >
            <MenuItem sx={{ gap: 1, padding: '14px 20px', borderBottom: '1px solid #EEEEEE' }}>
              <Telegram />
              <ListItemText>{data?.telegram || data?.phone}</ListItemText>
              <Copy />
            </MenuItem>
          </a>
        </CopyToClipboard>

        <a href={`https://zalo.me/${data?.zalo || data?.phone}`} target="_blank" rel="noreferrer">
          <MenuItem sx={{ gap: 1, padding: '14px 20px' }}>
            <Zalo />
            <ListItemText>{data?.zalo || data?.phone}</ListItemText>
          </MenuItem>
        </a>
      </MenuList>
    </div>
  )
})(({ theme }) => ({
  '& ul': {
    padding: '0px',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  '& a': {
    textDecoration: 'none',
    color: '#000',
  },
}))

export default UserInfoList
