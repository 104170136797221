const { Box, Typography, Stack, Tooltip } = require('@mui/material')
const { TicketApproveChip } = require('components/ticket/TicketApproveChip')
const { TicketStatusChip } = require('components/ticket/TicketStatusChip')
const { isSubTicket } = require('pages/ticket/utils')
const { Link } = require('react-router-dom')
const { formatDate } = require('utils/formater')
const { ability } = require('utils/permissions')
const { Button } = require('components/ui/Button')
// const newIcon = require("../assets/images/icons8-new-24.png")
const newIcon = require("../assets/images/icons8-new.svg").default

function useTicketColumns(userInfo = {}, onDeleteItem = () => { }) {

  return [
    {
      title: 'Vấn đề - Lỗi',
      name: 'name',
      formatter: (value, row, index) => {
        return (
          <Link
            to={{
              pathname: `/tickets/${row.id}`,
            }}
            style={{
              textDecorationLine: 'none'
            }}
          >
            <Box id={`name-${row.id}`} sx={{ color: '#000', width: '300px', whiteSpace: 'break-spaces', overflow: 'hidden' }}>
              <Box style={{ color: row.is_new ? "red" : 'black', display: 'flex', alignItems: "center", gap: 8 }}> {row.is_new && <img src={newIcon} alt='' />} {value}</Box>
              {isSubTicket(row) ? (
                <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_5163_157477)">
                        <rect width="20" height="20" transform="translate(0 0.132324)" fill="white" fillOpacity="0.01" />
                        <path
                          d="M2.60463 9.06659C2.61694 11.6978 4.76217 13.8355 7.39624 13.8355L15.321 13.8355L14.2046 14.9518C13.963 15.1935 13.963 15.5935 14.2046 15.8352C14.4463 16.0768 14.8463 16.0768 15.088 15.8352L17.2213 13.7018C17.463 13.4602 17.463 13.0602 17.2213 12.8185L15.088 10.6852C14.963 10.5602 14.8046 10.5018 14.6463 10.5018C14.488 10.5018 14.3213 10.5602 14.2046 10.6852C13.963 10.9268 13.963 11.3268 14.2046 11.5685L15.2216 12.5855L7.39624 12.5855C5.47428 12.5855 3.90043 11.0393 3.85556 9.12772L3.85254 9.12872L3.85254 4.87659C3.85254 4.53492 3.56921 4.25159 3.22754 4.25159C2.88587 4.25159 2.60254 4.53492 2.60254 4.87659L2.60254 9.06677L2.60463 9.06659Z"
                          fill="#333333"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5163_157477">
                          <rect width="20" height="20" fill="white" transform="translate(0 0.132324)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      padding: '8px 16px',
                      background: '#F5F5F5',
                      border: '1px solid #E5E5E5',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      flex: 1,
                    }}
                  >
                    <Box>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.0029 16.6034V5.24342C22.0029 3.53342 20.6029 2.13342 18.8929 2.13342H11.1129C9.92289 2.13342 8.88289 2.81342 8.36289 3.80342C8.19289 4.12342 8.45289 4.51342 8.82289 4.51342H12.8929C15.4329 4.51342 17.5029 6.58342 17.5029 9.12342V16.4434C17.5029 16.6234 17.6029 16.7934 17.7629 16.8834L19.6929 17.9634C20.9629 18.6634 22.0029 18.0534 22.0029 16.6034ZM5.11293 6.01331H12.8929C14.6029 6.01331 16.0029 7.41331 16.0029 9.12331V20.4833C16.0029 21.9333 14.9629 22.5433 13.6829 21.8433L9.75293 19.6533C9.34293 19.4233 8.66293 19.4233 8.24293 19.6533L4.31293 21.8433C3.04293 22.5533 2.00293 21.9333 2.00293 20.4833V9.12331C2.00293 7.41331 3.40293 6.01331 5.11293 6.01331Z"
                          fill="#2563EB"
                        />
                      </svg>
                    </Box>
                    <Box
                      sx={{
                        overflow: 'hidden',
                      }}
                    >
                      <Typography color="#737373">Yêu cầu gốc</Typography>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {row?.parent?.name || ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Link>
        )
      },
    },
    {
      title: 'Nơi làm việc',
      name: 'store.name',
      formatter: (value, row, index) => <Box sx={{ whiteSpace: 'nowrap' }}>{row.store?.name}</Box>,
    },
    {
      title: 'Vùng',
      name: 'store.areas.name',
      formatter: (value, row, index) => <Box sx={{ whiteSpace: 'nowrap' }}>{row.store?.areas?.name || ''}</Box>,
    },
    {
      title: 'Danh mục	',
      name: 'category.name',
      formatter: (value, row, index) => (
        <Stack flexWrap="nowrap" direction="row" spacing={2} alignItems="flex-start">
          <Box style={{ whiteSpace: 'nowrap' }}>{row.category?.name}</Box>

          <Tooltip title={row.category?.description}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99967 15.1659C4.04634 15.1659 0.833008 11.9525 0.833008 7.99919C0.833008 4.04585 4.04634 0.83252 7.99967 0.83252C11.953 0.83252 15.1663 4.04585 15.1663 7.99919C15.1663 11.9525 11.953 15.1659 7.99967 15.1659ZM7.99967 1.83252C4.59967 1.83252 1.83301 4.59919 1.83301 7.99919C1.83301 11.3992 4.59967 14.1659 7.99967 14.1659C11.3997 14.1659 14.1663 11.3992 14.1663 7.99919C14.1663 4.59919 11.3997 1.83252 7.99967 1.83252ZM7.99967 9.16585C7.72634 9.16585 7.49967 8.93919 7.49967 8.66585V5.33252C7.49967 5.05919 7.72634 4.83252 7.99967 4.83252C8.27301 4.83252 8.49967 5.05919 8.49967 5.33252V8.66585C8.49967 8.93919 8.27301 9.16585 7.99967 9.16585ZM7.74634 11.2791C7.82634 11.3124 7.91301 11.3324 7.99967 11.3324C8.08634 11.3324 8.17301 11.3124 8.25301 11.2791C8.33301 11.2458 8.40634 11.1991 8.47301 11.1391C8.53301 11.0724 8.57967 11.0058 8.61301 10.9191C8.64634 10.8391 8.66634 10.7524 8.66634 10.6658C8.66634 10.5791 8.64634 10.4924 8.61301 10.4124C8.57967 10.3324 8.53301 10.2591 8.47301 10.1924C8.40634 10.1324 8.33301 10.0858 8.25301 10.0524C8.09301 9.98577 7.90634 9.98577 7.74634 10.0524C7.66634 10.0858 7.59301 10.1324 7.52634 10.1924C7.46634 10.2591 7.41967 10.3324 7.38634 10.4124C7.35301 10.4924 7.33301 10.5791 7.33301 10.6658C7.33301 10.7524 7.35301 10.8391 7.38634 10.9191C7.41967 11.0058 7.46634 11.0724 7.52634 11.1391C7.59301 11.1991 7.66634 11.2458 7.74634 11.2791Z"
                fill="#2560E5"
              />
            </svg>
          </Tooltip>
        </Stack>
      ),
    },
    {
      title: 'Nhãn',
      name: 'tag.name',
      formatter: (value, data, index) => <Box sx={{ whiteSpace: 'nowrap' }}>{data.tag?.name}</Box>,
    },
    {
      title: 'Trạng thái',
      name: 'status',
      formatter: (value, row, index) => <TicketStatusChip status={value} />,
    },
    {
      title: 'Trạng thái phê duyệt',
      name: 'status_approve',
      formatter: (value, row, index) => <TicketApproveChip status={value} />,
    },
    {
      title: 'Thời gian tạo',
      name: 'created_at',
      // sortable: true,
      align: 'right',
      formatter: (value, row, index) => value && formatDate(value),
    },
    {
      title: 'Thời gian hết hạn',
      name: 'deadline',
      // sortable: true,
      align: 'right',
      formatter: (value, row, index) => value && formatDate(value),
    },
    {
      title: 'Người tạo',
      name: 'created_by.name',
      formatter: (value, row, index) => <Box sx={{ whiteSpace: 'nowrap' }}>{row.created_by?.name}</Box>,
    },
    {
      title: 'Giao cho',
      name: 'assignee.name',
      formatter: (value, row, index) => <Box sx={{ whiteSpace: 'nowrap' }}>{row.assignee?.name}</Box>,
    },
    {
      title: 'Hành động',
      formatter: (value, row, index) => (
        <Stack direction="row" justifyContent="flex-end">
          <Tooltip title="Xem chi tiết" arrow>
            <Link
              to={{
                pathname: `/tickets/${row.id}`,
              }}
            >
              <Button typeButton="icon">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.833 14.801a.5.5 0 0 1 .5-.5h11.334a.5.5 0 1 1 0 1H2.333a.5.5 0 0 1-.5-.5ZM13.02 2.115a.5.5 0 0 0-.707 0L2.98 11.448a.5.5 0 0 0 .707.707l9.333-9.333a.5.5 0 0 0 0-.707Z"
                    fill="#525252"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.166 2.468a.5.5 0 0 0-.5-.5H5.82a.5.5 0 0 0 0 1h6.346v6.347a.5.5 0 0 0 1 0V2.468Z"
                    fill="#525252"
                  />
                </svg>
              </Button>
            </Link>
          </Tooltip>
          {ability(ability.TicketDelete, row, userInfo) && (
            <Tooltip title="Xóa" arrow>
              <div>
                <Button sx={{ ml: 1 }} typeButton="icon" onClick={() => onDeleteItem(row.id)}>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="m5.32 2.495-.143.849c-.624.033-1.248.081-1.87.144l-1.36.134a.503.503 0 0 0 .107 1l1.36-.134c3.486-.353 7.006-.22 10.533.134H14a.494.494 0 0 0 .493-.454.5.5 0 0 0-.446-.546 76.772 76.772 0 0 0-3.22-.252v-.002l-.147-.867v-.003c-.1-.614-.25-1.53-1.807-1.53H7.127c-1.553 0-1.7.886-1.807 1.525v.002Zm4.38.173.11.654A51.415 51.415 0 0 0 6.2 3.3l.113-.639.001-.006c.1-.588.115-.68.82-.68H8.88c.707 0 .727.113.82.693ZM5.86 15.301h4.28c2.327 0 2.42-1.287 2.493-2.326l.434-6.714a.5.5 0 0 0-.467-.533.51.51 0 0 0-.533.466l-.434 6.714c-.066 1.013-.093 1.393-1.493 1.393H5.86c-1.393 0-1.42-.38-1.493-1.391v-.002l-.434-6.713a.505.505 0 0 0-.533-.467.505.505 0 0 0-.467.533l.434 6.714c.073 1.04.166 2.326 2.493 2.326Zm3.247-3.666h-2.22a.504.504 0 0 1-.5-.5c0-.274.226-.5.5-.5h2.22c.273 0 .5.226.5.5 0 .273-.227.5-.5.5ZM6.333 8.968h3.334c.273 0 .5-.227.5-.5s-.227-.5-.5-.5H6.333c-.273 0-.5.227-.5.5s.227.5.5.5Z"
                      fill="#525252"
                    />
                  </svg>
                </Button>
              </div>
            </Tooltip>
          )}
        </Stack>
      ),
    },
  ]
}

export default useTicketColumns
