import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material'
import { FieldFileAttachment, FieldRadioGroup, FieldSelector, FieldTextInput } from 'components/form'
import { Button as ButtonCus, Modal, Text, useModal } from 'components/ui'
import { MESSAGE_TYPES } from 'constants/common'
import { Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as categoryService from 'services/category.service'
import * as storeService from 'services/store.service'
import * as ticketService from 'services/ticket.service'
import * as userService from 'services/user.service'
import { createMessage } from 'utils/common'
import * as Yup from 'yup'
import CheckIcon from '@mui/icons-material/Check'
import { FieldUserSelector } from 'components/UserSelector'
import { FieldCategorySelector } from 'components/CategorySelector'

const ModalCreateTicket = ({ onCreated, isSubTicket = false, parent = null }) => {
  const dispatch = useDispatch()
  const refForm = useRef()
  const [refModal, { toggle: toggleModal }] = useModal()
  const [isShow, setIsShow] = useState(false);

  const user = useSelector((state) => state.user.user)

  const [state, setState] = useState({
    isLoading: false,
    categories: [],
    stores: [],
  })

  const handleSave = async (values, { setSubmitting }) => {
    setSubmitting(true)

    try {
      const prepareValues = {
        ...values,
        category: values.category?.id,
        store: values.store?.id,
        raise_ticket_by: values.showRaiseBy ? values.raise_ticket_by?.id : user.id,
        attachments: (values.attachments || []).map((attachment) => attachment.id),
      }

      await ticketService.createTicket({
        ...prepareValues,
      })

      toggleModal(false)

      onCreated()
      setSubmitting(false)

      dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Thêm yêu cầu thành thông' }))
    } catch (error) {
      setSubmitting(false)
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: error.message }))
    }
  }

  useEffect(() => {
    isShow && init()
  }, [isShow])

  const init = async () => {
    const categories = await categoryService.getList({ paginate: 0, allow_create_ticket: 1 }).catch((e) => [])
    const stores = await storeService.getList({ paginate: 0 }).catch((e) => [])
    setState({ ...state, categories, stores })
  }

  return (
    <Stack direction="column" alignItems="center">
      {isSubTicket ? (
        <ButtonCus
          typeButton="normal"
          style={{
            height: '2.5rem',
          }}
          startIcon={<AddCircleIcon />}
          onClick={() => toggleModal(true)}
        >
          {'Thêm yêu cầu phụ'}
        </ButtonCus>
      ) : (
        <ButtonCus
          typeButton="primary"
          style={{
            height: '2.5rem',
          }}
          startIcon={<AddCircleIcon />}
          onClick={() => toggleModal(true)}
        >
          {'Thêm mới'}
        </ButtonCus>
      )}

      <Modal
        ref={refModal}
        title={
          <Text textAlign={'center'} fontWeight={'bold'} fontSize={16}>
            {isSubTicket ? 'Thêm yêu cầu phụ' : 'Tạo yêu cầu'}
          </Text>
        }
        maxWidth="md"
        includeDialogContent={false}
        backdrop="static"
        onShow={() => {
          setIsShow(true)
        }}
        onHide={() => {
          setIsShow(false)
        }}
      >
        <Formik
          initialValues={{
            showRaiseBy: 0,
            raise_ticket_by: undefined,
            category: undefined,
            store: undefined,
            name: '',
            description: '',
            parent,
          }}
          validationSchema={() => {
            return Yup.object().shape({
              showRaiseBy: Yup.number(),
              raise_ticket_by: Yup.object()
                .nullable()
                .when('showRaiseBy', {
                  is: (showRaiseBy) => showRaiseBy == '1',
                  then: Yup.object().requireObj(),
                }),
              name: Yup.string().min(5).required('Vui lòng nhập tên yêu cầu'),
              category: Yup.object().requireObj('Vui lòng chọn danh mục'),
              // tag: Yup.string().required('Vui lòng chọn nhãn'),
              store: Yup.object().requireObj('Vui lòng chọn nơi làm việc'),
              description: Yup.string().min(5).max(1000).required(),
            })
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values, { setSubmitting })
          }}
        >
          {(props) => {
            refForm.current = props

            return (
              <form noValidate onSubmit={props.handleSubmit}>
                <DialogContent dividers>
                  <FieldRadioGroup
                    name="showRaiseBy"
                    label="Tạo yêu cầu"
                    requiredLabel
                    propsContainer={{ component: 'div', style: { width: '100%' } }}
                    options={[
                      { value: 0, label: 'Cho chính tôi' },
                      { value: 1, label: 'Tạo hộ yêu cầu' },
                    ]}
                    optionKey={(e) => e.value}
                    optionLabel={(e) => e.label}
                    renderRadio={({ Radio }) => (
                      <Grid container spacing={2} rowSpacing={1}>
                        <Grid item xs={12} sm={6}>
                          {Radio.Radio1}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {Radio.Radio2}
                        </Grid>
                      </Grid>
                    )}
                    onChange={() => {
                      props.setFieldValue('store', undefined)
                    }}
                  />
                  {[1, '1'].includes(props.values.showRaiseBy) && (
                    <Grid container spacing={2} rowSpacing={1} mb={1}>
                      <Grid item xs={12} sm={12}>
                        <FieldUserSelector
                          name="raise_ticket_by"
                          labelOutline
                          placeholder="Chọn người có yêu cầu cần xử lý"
                          optionKey={(e) => e?.id}
                          optionLabel={(e) => (!e ? '' : `${e.name}`)}
                          onFetch={async (params) => {
                            const resp = await userService
                              .getUsers({
                                search: params.search,
                                page: params.page,
                                per_page: params.pageSize,
                              })
                              .catch((e) => ({ data: [], pagination: { total: 0 } }))
                            return { rows: resp.data, total: resp.pagination.total }
                          }}
                          propsContainer={{ sx: { mt: 1 } }}
                          onSelected={(val) => {
                            props.setFieldValue('store', null)
                            props.setFieldValue('raise_ticket_by', val)
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2} rowSpacing={1} mb={1}>
                    <Grid item xs={12} sm={12}>
                      <FieldCategorySelector
                        name="category"
                        label="Danh mục"
                        requiredLabel
                        placeholder="Chọn loại vấn đề"
                        options={state.categories}
                        optionKey={(e) => e?.id}
                        optionLabel={(e) => e?.name}
                        propsContainer={{ sx: { mt: 1 } }}
                        onSelected={(val) => {
                          props.setFieldValue('category', val.id)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} rowSpacing={1} mb={1}>
                    <Grid item xs={12} sm={6}>
                      <FieldTextInput
                        name="name"
                        label="Tên yêu cầu"
                        requiredLabel
                        placeholder="Nhập tiêu đề của yêu cầu"
                        propsContainer={{ sx: { mt: 1 } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FieldSelector
                        name="store"
                        label="Nơi làm việc"
                        requiredLabel
                        placeholder="Chọn nơi phát sinh yêu cầu"
                        options={
                          props.values.showRaiseBy == '1'
                            ? props.values.raise_ticket_by?.stores || []
                            : user.stores || []
                        }
                        optionKey={(e) => e?.id}
                        optionLabel={(e) => e?.name}
                        propsContainer={{ sx: { mt: 1 } }}
                        onSelected={(val) => {
                          props.setFieldValue('store', val.id)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} rowSpacing={1} mb={1}>
                    <Grid item xs={12} sm={12}>
                      <FieldTextInput
                        name="description"
                        label="Mô tả"
                        requiredLabel
                        placeholder="Nhập nội dung"
                        propsContainer={{ sx: { mt: 1 } }}
                        multiline
                        rows="4"
                        description="Giới hạn 1000 ký tự"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} rowSpacing={1}>
                    <Grid item xs={12} sm={12}>
                      <FieldFileAttachment
                        type="post"
                        name="attachments"
                        label="Đính kèm tài liệu"
                        propsContainer={{ sx: { mt: 1 } }}
                        acceptGroup={['office', 'image']}
                        onUpload={async (e) => {
                          setState({
                            ...state,
                            isLoading: true,
                          })

                          const resp = await ticketService
                            .uploadAttachmentFile(e.file)
                            .catch((e) => ({ error: e.message }))

                          setState({
                            ...state,
                            isLoading: false,
                          })
                          return {
                            url: resp?.link,
                            error: resp?.error,
                            id: resp?.id,
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box display={'flex'} alignItems={'center'} width={'100%'}>
                    <Stack direction="row" justifyContent={'flex-end'} spacing={1} style={{ flex: 1 }}>
                      <ButtonCus typeButton="secondary" onClick={() => toggleModal(false)}>
                        {'Hủy bỏ'}
                      </ButtonCus>
                      <ButtonCus
                        type="submit"
                        typeButton="primary"
                        disabled={props.isSubmitting || state.isLoading}
                        isLoading={props.isSubmitting}
                      >
                        {'Tạo yêu cầu'}
                      </ButtonCus>
                    </Stack>
                  </Box>
                </DialogActions>
              </form>
            )
          }}
        </Formik>
      </Modal>
    </Stack>
  )
}
export default ModalCreateTicket
